import { AfterContentChecked, ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '@shared/services/modal.service';
import { LoaderService } from '@shared/services/loader.service';
import { CustomerService } from '@shared/services/customer.service';
import { ActivationEnd, ActivationStart, Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { User } from '@shared/models/user';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CockpitService } from './modules/cockpit/services/cockpit.service';
import { TranslateService } from '@ngx-translate/core';
import { languages } from 'src/constants';
import { TermsAndPrivacyComponent } from '@shared/modals/terms-and-privacy/terms-and-privacy-modal.component';
import { BaseModalComponent } from './shared/components/base-modal/base-modal.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
    @ViewChild(RouterOutlet) outlet: RouterOutlet;
    private previousActivatedComponent: string; // componentName
    private userSubscription: Subscription;
    private modalRef: ComponentRef<BaseModalComponent> | null = null;

    public isSafari = false;
    public user: User;
    public title = 'DURVT Team Performance Software - Ratings';
    public isCockpit = false;
    public cockpitMenuItems = [
        { label: 'COCKPIT.MENU_ITEMS.MATRIX', route: 'quads', icon: 'grid-2', permissionId: 'quad' },
        { label: 'COCKPIT.MENU_ITEMS.RADAR', route: 'polars', icon: 'radar', permissionId: 'polar' },
        { label: 'COCKPIT.MENU_ITEMS.DEVELOPMENT_ACTIVITY', route: 'activity', icon: 'list-check', permissionId: 'activity' },
        { label: 'COCKPIT.MENU_ITEMS.HEART_RATE_MONITOR', route: 'heartbeats', icon: 'heart-pulse', permissionId: 'heartbeat' },
        { label: 'COCKPIT.MENU_ITEMS.MEASUREMENT_OVERVIEW', route: 'measurement-overview', icon: 'calendar', permissionId: 'measurement' }
    ];

    constructor(
        private authService: AuthService,
        private customerService: CustomerService,
        private modalService: ModalService,
        private router: Router,
        private ref: ChangeDetectorRef,
        private cockpitService: CockpitService,
        private translateService: TranslateService,
        public loaderService: LoaderService,
    ) {
        this.authService.me().subscribe();

        Chart.pluginService.unregister(ChartDataLabels);
    }

    ngAfterContentChecked() {
        this.ref.detectChanges();
    }

    ngOnInit() {
        this.userSubscription = this.authService.user.subscribe((user: User) => {
            this.user = user;

            this.translateService.use(
                this?.user?.locale ?? this.determineAppLanguage()
            );

            if (this.user?.accepted_terms_at === null && this.modalRef === null) {
                this.modalRef = this.modalService.init(TermsAndPrivacyComponent, null);
            }
        });

        this.isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1;

        this.router.events.subscribe(event => {
            // TODO: See if there is a better way of doing this
            if (event instanceof ActivationStart) {
                const comp = event.snapshot.component as any;
                if (
                    event.snapshot.outlet === 'sidebar' &&
                    this.outlet.isActivated &&
                    comp &&
                    comp.name &&
                    this.previousActivatedComponent !== comp.name
                ) {
                    // Manually deactivate sidebar route if new routing component is different from previous route
                    this.outlet.deactivate();
                }
            }
            if (event instanceof ActivationEnd) {
                this.isCockpit = /cockpit/.test(window.location.pathname);
                if (event.snapshot.outlet === 'sidebar') {
                    const comp = event.snapshot.component as any;
                    if (comp && comp.name) {
                        this.previousActivatedComponent = comp.name;
                    }
                }
            }
        });
    }

    disableCockpit() {
        return !this.cockpitService.customerId;
    }

    removeModal() {
        this.modalService.destroy();
    }

    clearCustomerEditing() {
        this.customerService.isEditing.next(false);
    }

    onCockpitSubMenuItemClick(event) {
        if (event === 'cockpit') {
            this.router.navigate(['/cockpit']);
        } else {
            this.router.navigate(['/cockpit', event]);
        }
    }

    determineAppLanguage() {
        const browserLanguages = navigator.languages;

        for (const browserLanguage of browserLanguages) {
            return languages.find((language) => language.browserId === browserLanguage)?.id;
        }

        return 'nl_NL';
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }
}
