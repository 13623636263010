export const languages: {
    label: string;
    id: string;
    browserId?: string;
    showInMenu?: boolean;
}[] = [
    { label: 'Nederlands', id: 'nl_NL', browserId: 'nl-NL', showInMenu: true },
    { label: 'Nederlands', id: 'nl_NL', browserId: 'nl', showInMenu: false },
    { label: 'Engels (GB)', id: 'en_GB', browserId: 'en-GB', showInMenu: true },
    { label: 'Engels', id: 'en_GB', browserId: 'en', showInMenu: false },
];
