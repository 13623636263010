import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { MeasurementService } from './measurement.service';
import * as Moment from 'moment';
import { mergeMap } from 'rxjs/operators';
import { ColorService } from './color.service';
import { TeamService } from './team.service';
import { CustomerService } from './customer.service';

interface Result {
    avg: string;
    category_name: string;
    theme_name: string;
}

interface Measurement {
    end_date: string;
    start_date: string;
    name: string;
    results: Result[];
}

interface SpiderChartResponse {
    measurement: Measurement;
    previous_measurement: Measurement;
}

interface SpiderChartData {
    labels: string[];
    data: { label: string, data: string[], backgroundColor?: string, borderColor?: string }[];
}

@Injectable({ providedIn: 'root' })
export class SpiderChartResolver implements Resolve<SpiderChartData> {
    private data: SpiderChartData = {
        labels: [],
        data: []
    };

    private colors = [
        {
            backgroundColor: this.colorService.getColor('blue', 400, 'rgba', 0.5),
            borderColor: this.colorService.getColor('blue', 500, 'rgba', 0.5),
            pointBackgroundColor: this.colorService.getColor('blue', 400, 'rgb'),
            pointBorderColor: this.colorService.getColor('blue', 500, 'rgb'),
        },
        {
            backgroundColor: this.colorService.getColor('magenta', 400, 'rgba', 0.5),
            borderColor: this.colorService.getColor('magenta', 500, 'rgba', 0.5),
            pointBackgroundColor: this.colorService.getColor('magenta', 400, 'rgb'),
            pointBorderColor: this.colorService.getColor('magenta', 500, 'rgb'),
        }
    ];

    constructor(
        private measurementService: MeasurementService,
        private colorService: ColorService,
        private customerService: CustomerService,
        private teamService: TeamService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const customerId = route.paramMap.get('customerId');
        const teamId = route.paramMap.get('teamId');
        const templateId = route.paramMap.get('templateId');
        const customer = this.customerService.customer.getValue();
        const team = this.teamService.activeTeam.getValue();
        const measurement = this.measurementService.activeMeasurementTemplate.getValue();
        return of({customerId, teamId, templateId}).pipe(
            mergeMap(
                (data) => {
                    return forkJoin({
                        customer: (!customer || String(customer.id)  !== customerId)
                            && customerId !== null  ? this.customerService.getCustomer(data.customerId) : [{
                            customer,
                        }],
                        team: !team || String(team.id)  !== teamId ? this.teamService.getTeam(data.teamId) : [
                            team,
                        ],
                        measurement: !measurement
                            || String(measurement.id)  !== templateId ? this.measurementService.getTemplate(data.templateId) : [
                            measurement,
                        ],
                        chartData: this.measurementService.getSpiderChartData(data.templateId)
                        .pipe(
                            mergeMap((response: SpiderChartResponse) => {
                                this.data = {
                                    labels: [],
                                    data: []
                                };
                                this.formatData(response);
                                return of(this.data);
                            })
                        )
                    });
                }
            )
        );
    }

    formatData(response: SpiderChartResponse) {
        this.addChartLabels(response.measurement);
        this.addChartLabels(response.previous_measurement);
        this.addChartData(response.measurement);
        this.addChartData(response.previous_measurement);
    }

    addChartLabels(measurement: Measurement) {
        if (!measurement) { return; }

        measurement.results.forEach(result => {
            if (this.data.labels.indexOf(result.theme_name) === -1) {
                this.data.labels.push(result.theme_name);
            }
        });
    }

    addChartData(measurement: Measurement) {
        if (!measurement) { return; }
        const arrLength = this.data.data.push({
            label: Moment.utc(measurement.end_date).format('DD/MM/YYYY'),
            data: []
        });
        const charDataIndex = arrLength - 1;

        this.data.data[charDataIndex] = Object.assign({}, this.data.data[charDataIndex], this.colors[charDataIndex]);

        this.data.labels.forEach((label, index) => {
            const result = measurement.results.find(data => data.theme_name === label);
            if (result) {
                this.data.data[charDataIndex].data[index] = result.avg;
            }
        });
    }
}
