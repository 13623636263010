import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { MeasurementService } from './measurement.service';
import { mergeMap } from 'rxjs/operators';
import { TeamService } from './team.service';
import { CustomerService } from './customer.service';

interface QuadChartResponse {
    categories: {
        [key: string]: {
            highest: number,
            lowest: number
        }
    };
}

@Injectable({ providedIn: 'root' })
export class QuadChartResolver implements Resolve<QuadChartResponse> {

    constructor(
        private measurementService: MeasurementService,
        private customerService: CustomerService,
        private teamService: TeamService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const customerId = route.paramMap.get('customerId');
        const teamId = route.paramMap.get('teamId');
        const templateId = route.paramMap.get('templateId');
        const customer = this.customerService.customer.getValue();
        const team = this.teamService.activeTeam.getValue();
        const measurement = this.measurementService.activeMeasurementTemplate.getValue();
        return of({customerId, teamId, templateId}).pipe(
            mergeMap(
                (data) => {
                    return forkJoin({
                        customer: (!customer || String(customer.id) !== customerId )
                            && customerId !== null ? this.customerService.getCustomer(data.customerId) : [{
                            customer,
                        }],
                        team: !team || String(team.id)  !== teamId ? this.teamService.getTeam(data.teamId) : [
                            team,
                        ],
                        measurement: !measurement
                            || String(measurement.id)  !== templateId ? this.measurementService.getTemplate(data.templateId) : [
                            measurement,
                        ],
                        chartData: this.measurementService.getQuadChartData(data.templateId)
                    });
                }
            )
        );
    }
}
